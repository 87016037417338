import barba from '@barba/core'

barba.init({
  views: [
    require('views/about'),
    require('views/home'),
    require('views/project'),
    require('views/projects'),
    require('views/team-member')
  ].map(v => v.default),
  prefetchIgnore: true,
  transitions: [{
    once: ({ next }) => {
      document.body.classList.remove('is-loading')
    },

    enter: () => {
      window.scrollTo(0, 0)
    }
  }],
  prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),
  requestError: (trigger, action, url, response) => barba.force(url)
})
