import BarbaView from 'abstractions/BarbaView'
import initLozad from 'controllers/init-lozad'
import Photoswipe from 'components/Photoswipe'

export default new BarbaView('project', {
  afterEnter: function ({ next }) {
    initLozad.call(this, next.container)

    this.refs.photoswipe = new Photoswipe({
      figures: next.container.querySelectorAll('figure')
    })
    this.refs.photoswipe.hydrate(document.querySelector('.pswp'))
  }
})
